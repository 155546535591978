import './style.css'
import mapboxgl from "mapbox-gl";
import React, {useEffect, useRef} from "react";
import {mapLiveAPIRequest} from "../../pages/map/utils/mapPreviewMode";
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getFilteredLegendsData,
    getLegends, getMapStylesExtraLayers, getScreenshotLanguage,
    getSelectedMapStyle
} from "../../store/selectors";
import {connect} from "react-redux";
import {getLegendsFromAPI} from "../../store/actions/legends";
import {determineCatIds} from "../../pages/map/utils";
import {getCategoriesFromAPI, getFilteredCategoriesFromAPI} from "../../store/actions/categories";
import ArticleModeHeader from "../articleModeHeader";
import {getSpriteFromAPI} from "../../store/actions/sprite";
import i18n from "i18next";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const BasicMap = (props) =>{
    const {content, getSpriteFromAPI,screenshotLanguage} = props
    const map = useRef(null)
    const mapContainer = useRef(null)
    const handleGetMapStyle = () => {
        return props.selectedMapStyle[props.selectedMapStyle.active]
    }
    const setupMap = async () =>{
        if (!mapContainer.current) return;
        const lng = localStorage.getItem ('language') === 'en' ? 2 : 1
        const styleId = props.selectedMapStyle[props.selectedMapStyle?.active]
        if (map.current) {
            const gl = map.current.getCanvas().getContext('webgl');
            if (gl) {
                const loseContextExt = gl.getExtension('WEBGL_lose_context');
                if (loseContextExt) {
                    loseContextExt.loseContext();
                }
            }
            map.current.remove();
        }
        map.current = await new mapboxgl.Map({
            container: mapContainer.current,
            style: `${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}?articleId=${content?.articleId}`,
            center: [0, 0],
            maxZoom: 8.1,
            zoom: 1,
            transformRequest: (url, resourceType) => {
                let sendUrl = url;
                const gisUrl = localStorage.getItem('testTiles') || process.env.REACT_APP_GIS_URL;

                if (sendUrl === `${gisUrl}/BaseSprite/sprite@2x.png`) {
                    return {
                        url: `${sendUrl}?icon=${new Date().getTime()}`,
                        headers: { "access-control-allow-origin": "*" },
                    };
                }

                if (!url.startsWith(gisUrl)) return { url: sendUrl };

                // Tiles-related transformations
                if (url.startsWith(`${gisUrl}/Tiles`)) {
                    const params = new URLSearchParams(sendUrl.includes('?') ? sendUrl.split('?')[1] : '');

                    // Append missing parameters if not already present
                    if (!params.has('articleId')) params.append('articleId', content?.articleId);
                    if (!params.has('year')) params.append('year', content?.year);
                    if (!params.has('baseMapId')) params.append('baseMapId', handleGetMapStyle());
                    if (!params.has('languageId')) params.append('languageId', screenshotLanguage || lng);
                    sendUrl = `${sendUrl.split('?')[0]}?${params.toString()}&isBc=${content?.isBc}`;

                    return {
                        url: sendUrl,
                        headers: { 'Cache-Control': 'public' },
                    };
                }

                // Default case for GIS URL
                return {
                    url: sendUrl,
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
                };
            }
            // maxPitch:60,
        })

        const legendsBody = {
            [`articleId`]: content?.articleId,
            baseMapId:styleId || 1,
            includeSubLegends:true
        }
        props.getLegendsFromAPI(legendsBody,screenshotLanguage || lng)
            .then(async (legends) => {
                mapLiveAPIRequest(content?.articleId, 'article',screenshotLanguage || lng).then(async (resData) => {
                    if(!map.current) return
                    const categories = props.categories
                    const res = props.filteredCategories
                    const subCat = {};
                    const cat = {}
                    categories?.forEach?.((el)=> {
                        cat[el.id] = el
                        el?.subCategories?.forEach?.((item)=> {
                            subCat[item.id] = item
                        })
                    })
                    const features = []
                    if(resData.clusterElements.length > 0){
                        features.push(...resData.clusterElements.reduce((acum,el)=>{
                            // const subId = res.getById(el?.articleIds[0])?.subcategory[0]?.subCategoryId;
                            // const catId = res.getById(el?.articleIds[0])?.categoryId;

                            const article = res.getById(el?.articleIds[0])
                            const mainSub = article?.subcategory?.find(el=>el.isMain)
                            const mainCatId = mainSub?.categoryId || article?.categoryId
                            const mainSubId = mainSub?.subCategoryId

                            acum.push(
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [el.location.longitude, el.location.latitude]
                                    },
                                    properties: {
                                        id:el.id,
                                        relationType:el.lessonRelationType,
                                        articleIds:el.articleIds,
                                        name: el.displayName || 'no name ',
                                        yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                                        yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
                                        startTime:0,
                                        endTime:0,
                                        visible:'visible',
                                        relationFilter:'visible',
                                        // iconPath:subCat?.iconPath,
                                        iconPath:'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
                                        // color:subCat?.color || '#fff'
                                        key:  legends?.getKeyById(el?.layerId)?.icon || legends?.getKeyById(el?.layerId)?.key,
                                        subId:mainSubId,
                                        catId:mainCatId,
                                        color: cat[mainCatId]?.color,
                                        catColor:cat[mainCatId]?.color,
                                        // spriteIcon:cat[this.props.filteredCategories.getById(el?.articleIds[0])?.categoryId]?.spriteIcon,
                                        spriteIcon:legends?.getKeyById(el?.layerId)?.icon,
                                        element:true,
                                    }
                                }
                            )
                            return acum
                        },[]))
                    }
                    if(resData.clusterArticles.length > 0){
                        features.push(...resData.clusterArticles.reduce((acum,el,idx)=>{
                            const mainSub = el?.subcategory.find(el=>el.isMain)
                            const mainCatId = mainSub?.categoryId || el?.categoryId
                            const mainSubId = mainSub?.subCategoryId
                            acum.push(
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [el.location.longitude, el.location.latitude]
                                    },
                                    properties: {
                                        visible:'visible',
                                        relationFilter:'visible',
                                        id:el.id,
                                        relationType:el.lessonRelationType,
                                        articleIds:[el.id],
                                        name: el.content[0]?.shortTitle ? el.content[0]?.shortTitle : (el.content[0]?.mainTitle || 'no name '),
                                        yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                                        yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear() }`,
                                        startTime:0,
                                        endTime:0,
                                        catId:cat[mainCatId]?.id,
                                        catColor:cat[mainCatId]?.color,
                                        subId:subCat[`${mainSubId}`]?.id,
                                        spriteIcon:subCat[`${mainSubId}`]?.spriteIcon,
                                        // iconPath:subCat[`${mainSubId}`]?.iconPath,
                                        // spriteIcon:'c-land_mountain',
                                        // spriteIcon:subCat[`${mainSubId}`]?.spriteIcon,
                                        color:subCat[`${mainSubId}`]?.color || '#fff',
                                        key:subCat[`${mainSubId}`]?.key,
                                        isArticle:true,
                                        active:idx === 0,
                                    }
                                }
                            )
                            return acum
                        },[]))
                    }
                    const sourceOptions = {
                        type: 'geojson',
                        data: {
                            "type": "FeatureCollection",
                            "features": features
                        },
                        cluster: false,

                    }

                    if (map.current.getSource('Brainograph PIN GIS API')) {
                        map.current.removeSource('Brainograph PIN GIS API')
                    }

                    map.current?.addSource('Brainograph PIN GIS API', sourceOptions);
                    [props.mapStylesExtraLayers[0],props.mapStylesExtraLayers[1]].forEach(layer=>{
                        map.current?.addLayer(layer)
                    })
                })
            })
    }

    useEffect(() => {
        if (!map.current){
            // getSpriteFromAPI()
            setupMap()
        }
        return () => {
            if (map.current) {
                const gl = map.current.getCanvas().getContext('webgl');
                if (gl) {
                    const loseContextExt = gl.getExtension('WEBGL_lose_context');
                    if (loseContextExt) {
                        loseContextExt.loseContext();
                    }
                }
                map.current.remove();
                map.current = null;
            }
        };
    }, [content.articleId]);

    return (
        <section className={'basic-map-box'}>
            <ArticleModeHeader id={content?.articleId}/>
            <div id={"basic-map"} ref={mapContainer}></div>
        </section>
    )
}
const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
    filteredCategories: getFilteredCategoriesData(state),
    legends:getLegends(state),
    selectedMapStyle: getSelectedMapStyle(state),
    mapStylesExtraLayers: getMapStylesExtraLayers(state),
    screenshotLanguage: getScreenshotLanguage(state),
})
const mapDispatchToProps = {
    getLegendsFromAPI,
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI,
    getSpriteFromAPI

}
export default connect(mapStateTopProps, mapDispatchToProps)(BasicMap);
